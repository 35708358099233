import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TravelItineraryAPI } from "../API";
import Button from "../Components/Button";
import AdminTravelItineraryForm from "../Components/AdminTravelItineraryForm";

const AddTravelItinerary = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const travelItineraryId = new URLSearchParams(location.search).get("id");

  const addTravelItineraryData = (values, resetForm, setShowNotification) => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${TravelItineraryAPI}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          travelItinerary: values.travelItinerary,
          imageGallery: values.imageGallery,
          tourOverview: values.tourOverview,
          tripOverview: {
            ...values.tripOverview,
            tripOverviewMainHighlights: values.trip_overview_main_highlights
              .split("\n")
              .filter(Boolean),
          },
          totalTravelCost: values.totalTravelCost,
          dayWiseItinerary: {
            ...values.dayWiseItinerary,
            dayWiseData: values.dayWiseItinerary.dayWiseData.map((item) => {
              return {
                ...item,
                morning: item.morning.split("\n").filter(Boolean),
                afternoon: item.afternoon.split("\n").filter(Boolean),
                evening: item.evening.split("\n").filter(Boolean),
                night: item.night.split("\n").filter(Boolean),
                dayOptions:
                  item.dayOptions.length > 0
                    ? item.dayOptions.map((item) => {
                        return {
                          optionTitle: item.optionTitle,
                          optionData: item?.optionData
                            ?.split("\n")
                            ?.filter(Boolean),
                        };
                      })
                    : [],
              };
            }),
          },
          tourHighlights: values.tourHighlights.split("\n").filter(Boolean),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };

  const updateTravelItineraryData = (
    values,
    resetForm,
    setShowNotification
  ) => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${TravelItineraryAPI}/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          travelItineraryId: travelItineraryId,
          travelItinerary: values.travelItinerary,
          imageGallery: values.imageGallery,
          tourOverview: values.tourOverview,
          tripOverview: {
            ...values.tripOverview,
            tripOverviewMainHighlights: values.trip_overview_main_highlights
              .split("\n")
              .filter(Boolean),
          },
          totalTravelCost: values.totalTravelCost,
          dayWiseItinerary: {
            ...values.dayWiseItinerary,
            dayWiseData: values.dayWiseItinerary.dayWiseData.map((item) => {
              return {
                ...item,
                morning: item?.morning?.includes("\n")
                  ? item?.morning?.split("\n")?.filter(Boolean)
                  : item.morning,
                afternoon: item?.morning?.includes("\n")
                  ? item?.afternoon?.split("\n")?.filter(Boolean)
                  : item?.afternoon,
                evening: item?.morning?.includes("\n")
                  ? item?.evening?.split("\n")?.filter(Boolean)
                  : item?.evening,
                night: item?.morning?.includes("\n")
                  ? item?.night?.split("\n")?.filter(Boolean)
                  : item?.night,
                dayOptions:
                  item?.dayOptions?.length > 0
                    ? item?.dayOptions?.map((item) => {
                        return {
                          optionTitle: item?.optionTitle,
                          optionData: item?.morning?.includes("\n")
                            ? item?.optionData?.split("\n")?.filter(Boolean)
                            : item?.optionData,
                        };
                      })
                    : [],
              };
            }),
          },
          tourHighlights: values.tourHighlights.split("\n").filter(Boolean),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };

  return (
    <div className="bg-[#F9FAFB] px-4 py-4">
      <div className="md:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 md:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins capitalize">
            Add travel itinerary
          </h3>
        </div>
        <Button
          text="Back"
          onClick={() => {
            navigate("/super_admin/dashboard/travel-itinerary");
          }}
        />
      </div>
      <AdminTravelItineraryForm
        addTravelItineraryData={addTravelItineraryData}
        updateTravelItineraryData={updateTravelItineraryData}
        travelItineraryId={travelItineraryId}
        API={TravelItineraryAPI}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
};

export default AddTravelItinerary;
