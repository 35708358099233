import logo from "./logo.svg";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import WebLayout from "./web/WebLayout";
import Home from "./web/pages/Home";
import AboutUs from "./web/pages/AboutUs";
import TourList from "./web/pages/TourList";
import Blog from "./web/pages/Blog";
import ContactUs from "./web/pages/ContactUs";
import PackageDetails from "./web/pages/PackageDetails";
import WebContext from "./context/WebContext";
import Login from "./admin/Login";
import AdminLayout from "./admin/AdminLayout";
import Dashboard from "./admin/Dashboard";
import FavouriteDestination from "./admin/FavouriteDestination";
import AdminBlog from "./admin/AdminBlog";
import AdminTourList from "./admin/AdminTourList";
import AddTourList from "./admin/AddTourList";
import AddBlog from "./admin/AddBlog";
import DremPlace from "./admin/DremPlace";
import checkAuth from "./app/auth";
import { useMemo } from "react";
import AdminTestimonial from "./admin/AdminTestimonial";
import AddTestimonial from "./admin/AddTestimonial";
import AddFavouriteDestination from "./admin/AddFavouriteDestination";
import HomePage from "./admin/HomePage";
import AboutPage from "./admin/AboutPage";
import TourListPage from "./admin/TourListPage";
import BlogPage from "./admin/BlogPage";
import ContactUsPage from "./admin/ContactUsPage";
import BlogDetails from "./web/pages/BlogDetails";
import Leads from "./admin/Leads";
import AddLeads from "./admin/AddLeads";
import BlogComment from "./admin/BlogComment";
import EditBlogComment from "./admin/EditBlogComment";
import TermAndCondition from "./web/pages/TermAndCondition";
import PrivacyPolicy from "./web/pages/PrivacyPolicy";
import TravelItinerary from "./admin/TravelItinerary";
import AddTravelItinerary from "./admin/AddTravelItinerary";
function App() {
  const location = useLocation().pathname;
  const token = useMemo(
    () => (location.includes("admin") ? checkAuth() : ""),
    [location]
  );
  return (
    <div>
      <WebContext>
        <Routes>
          <Route
            path="/"
            element={
              <WebLayout>
                <Home />
              </WebLayout>
            }
          />
          <Route
            path="/about"
            element={
              <WebLayout>
                <AboutUs />
              </WebLayout>
            }
          />
          <Route
            path="/tour-list"
            element={
              <WebLayout>
                <TourList />
              </WebLayout>
            }
          />
          <Route
            path="/blog"
            element={
              <WebLayout>
                <Blog />
              </WebLayout>
            }
          />
          <Route
            path="/blog/blog-detail/:id"
            element={
              <WebLayout>
                <BlogDetails />
              </WebLayout>
            }
          />
          <Route
            path="/contact-us"
            element={
              <WebLayout>
                <ContactUs />
              </WebLayout>
            }
          />
          <Route
            path="/tour-list/:id"
            element={
              <WebLayout>
                <PackageDetails />
              </WebLayout>
            }
          />
          <Route
            path="/favourite-destination/:id"
            element={
              <WebLayout>
                <PackageDetails />
              </WebLayout>
            }
          />
          <Route
            path="/terms-and-condition"
            element={
              <WebLayout>
                <TermAndCondition />
              </WebLayout>
            }
          />
          <Route
            path="/privacy-and-policy"
            element={
              <WebLayout>
                <PrivacyPolicy />
              </WebLayout>
            }
          />
          <Route path="/super_admin/login" element={<Login />} />
          <Route
            path="/super_admin/dashboard"
            element={<AdminLayout children={<Dashboard />} />}
          />
          <Route
            path="/super_admin/dashboard/top-destination"
            element={<AdminLayout children={<AdminTourList />} />}
          />
          <Route
            path="/super_admin/dashboard/top-destination/add"
            element={<AdminLayout children={<AddTourList />} />}
          />
          <Route
            path="/super_admin/dashboard/top-destination/edit"
            element={<AdminLayout children={<AddTourList />} />}
          />
          <Route
            path="/super_admin/dashboard/favourite-destination"
            element={<AdminLayout children={<FavouriteDestination />} />}
          />
          <Route
            path="/super_admin/dashboard/favourite-destination/add"
            element={<AdminLayout children={<AddFavouriteDestination />} />}
          />
          <Route
            path="/super_admin/dashboard/favourite-destination/edit"
            element={<AdminLayout children={<AddFavouriteDestination />} />}
          />
          <Route
            path="/super_admin/dashboard/blog-post"
            element={<AdminLayout children={<AdminBlog />} />}
          />
          <Route
            path="/super_admin/dashboard/blog-post/add"
            element={<AdminLayout children={<AddBlog />} />}
          />
          <Route
            path="/super_admin/dashboard/blog-post/edit"
            element={<AdminLayout children={<AddBlog />} />}
          />
          <Route
            path="/super_admin/dashboard/testimonial"
            element={<AdminLayout children={<AdminTestimonial />} />}
          />
          <Route
            path="/super_admin/dashboard/testimonial/add"
            element={<AdminLayout children={<AddTestimonial />} />}
          />
          <Route
            path="/super_admin/dashboard/lead"
            element={<AdminLayout children={<Leads />} />}
          />
          <Route
            path="/super_admin/dashboard/lead/add"
            element={<AdminLayout children={<AddLeads />} />}
          />
          <Route
            path="/super_admin/dashboard/lead/edit"
            element={<AdminLayout children={<AddLeads />} />}
          />
          <Route
            path="/super_admin/dashboard/blog-comment"
            element={<AdminLayout children={<BlogComment />} />}
          />
          <Route
            path="/super_admin/dashboard/blog-comment/edit"
            element={<AdminLayout children={<EditBlogComment />} />}
          />
          <Route
            path="/super_admin/dashboard/testimonial/edit"
            element={<AdminLayout children={<AddTestimonial />} />}
          />
          <Route
            path="/super_admin/dashboard/travel-gallery"
            element={<AdminLayout children={<DremPlace />} />}
          />
          <Route
            path="/super_admin/dashboard/home-page"
            element={<AdminLayout children={<HomePage />} />}
          />
          <Route
            path="/super_admin/dashboard/about-page"
            element={<AdminLayout children={<AboutPage />} />}
          />
          <Route
            path="/super_admin/dashboard/tour-list-page"
            element={<AdminLayout children={<TourListPage />} />}
          />
          <Route
            path="/super_admin/dashboard/blog-page"
            element={<AdminLayout children={<BlogPage />} />}
          />
          <Route
            path="/super_admin/dashboard/contact-page"
            element={<AdminLayout children={<ContactUsPage />} />}
          />
          {/* Travel Itinerary */}
          <Route
            path="/super_admin/dashboard/travel-itinerary"
            element={<AdminLayout children={<TravelItinerary />} />}
          />
          <Route
            path="/super_admin/dashboard/travel-itinerary-add"
            element={<AdminLayout children={<AddTravelItinerary />} />}
          />
          <Route
            path="/super_admin/dashboard/travel-itinerary-edit"
            element={<AdminLayout children={<AddTravelItinerary />} />}
          />
          <Route
            path="/super_admin/*"
            element={
              <Navigate
                to={token ? "/super_admin/dashboard" : "/super_admin/login"}
                replace
              />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </WebContext>
    </div>
  );
}

export default App;
