import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import FloatNotification from "./FloatNotification";
import Loading from "./Loading";
import ImageUplaod from "./ImageUplaod";
import { ImageAPI } from "../API";
import InputText from "./InputText";
import MDInput from "./MDInput";
import { PencilIcon } from "@heroicons/react/24/outline";

const AdminTravelItineraryForm = ({
  addTravelItineraryData,
  updateTravelItineraryData,
  travelItineraryId,
  API,
  loading,
  setLoading,
}) => {
  let ignore = false;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    handleSubmit,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      travelItinerary: "",
      gallery_img: "",
      imageGallery: [],
      tourOverview: {
        tourOverviewDescription: "",
        tourOverviewDuration: "",
        tourOverviewDestinations: "",
        tourOverviewExclusions: "",
      },
      tripOverview: {
        tripOverviewDescription: "",
      },
      trip_overview_main_highlights: "",
      totalTravelCost: {
        travelCostTitle: "",
        travelCostImage: "",
        travelCostData: [],
      },
      category: "",
      minPrice: "",
      maxPrice: "",
      note: "",
      dayWiseItinerary: {
        mainTitle: "",
        dayWiseData: [],
      },
      dayTitle: "",
      morning: "",
      afternoon: "",
      evening: "",
      night: "",
      dayImage: "",
      dayOptions: [],
      optionTitle: "",
      optionData: "",
      tourHighlights: "",
    },
    validationSchema: yup.object().shape({
      travelItinerary: yup.string().when([], {
        is: () => !travelItineraryId,
        then: (schema) => schema.required("Travel Itinerary Is Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      imageGallery: yup.array().when([], {
        is: () => !travelItineraryId,
        then: (schema) =>
          schema
            .min(1, "Please Add at least 1 Image")
            .required("Gallery Is Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      tourOverview: yup.object().shape({
        tourOverviewDescription: yup.string().when([], {
          is: () => !travelItineraryId,
          then: (schema) =>
            schema.required("Tour Overview Description Is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        tourOverviewDuration: yup.string().when([], {
          is: () => !travelItineraryId,
          then: (schema) =>
            schema.required("Tour Overview Duration Is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        tourOverviewDestinations: yup.string().when([], {
          is: () => !travelItineraryId,
          then: (schema) =>
            schema.required("Tour Overview Destinations Is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        tourOverviewExclusions: yup.string().when([], {
          is: () => !travelItineraryId,
          then: (schema) =>
            schema.required("Tour Overview Exclusions Is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
      tripOverview: yup.object().shape({
        tripOverviewDescription: yup.string().when([], {
          is: () => !travelItineraryId,
          then: (schema) => schema.required("Trip Overview Title Is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
      trip_overview_main_highlights: yup.string().when([], {
        is: () => !travelItineraryId,
        then: (schema) =>
          schema
            .min(1, "Please Add Trip Main Highlight")
            .required("Trip Overview Main Highlights Is Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      totalTravelCost: yup.object().shape({
        travelCostTitle: yup.string().when([], {
          is: () => !travelItineraryId,
          then: (schema) => schema.required("Travel Cost Title Is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        travelCostImage: yup.string().when([], {
          is: () => !travelItineraryId,
          then: (schema) => schema.required("Travel Cost Image Is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        travelCostData: yup.array().when([], {
          is: () => !travelItineraryId,
          then: (schema) =>
            schema.min(1, "Please Add at least 1 Cost").required(),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
      dayWiseItinerary: yup.object().shape({
        mainTitle: yup.string().when([], {
          is: () => !travelItineraryId,
          then: (schema) => schema.required("Main Title Is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        dayWiseData: yup.array().when([], {
          is: () => !travelItineraryId,
          then: (schema) =>
            schema.min(1, "Please Add at least 1 Day Wise Data").required(),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
      tourHighlights: yup.string().when([], {
        is: () => !travelItineraryId,
        then: (schema) => schema.required("Tour Highlights Is Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: (value, errors) => {
      location.pathname.includes("add")
        ? addTravelItineraryData(values, resetForm, setShowNotification)
        : updateTravelItineraryData(values, resetForm, setShowNotification);
    },
  });

  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  const [travelItineraryEditIndex, setTravelItineraryEditIndex] =
    useState(null);
  const [isEditTravelCost, setIsEditTravelCost] = useState(false);
  const [travelCostIndex, setTravelCostIndex] = useState(null);
  const [isEditDayDetails, setIsEditDayDetails] = useState(false);
  const [dayDetailsIndex, setDayDetailsIndex] = useState(null);

  const getSingleTravelItinerary = () => {
    const token = localStorage.getItem("admin_token");
    setLoading(true);
    if (token) {
      fetch(`${API}/get-by-id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ travelItineraryId: travelItineraryId }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            setFieldValue("travelItinerary", data.data.travelItinerary);
            setFieldValue("imageGallery", data.data.imageGallery);
            setFieldValue("tourOverview", data.data.tourOverview);
            setFieldValue(
              "tripOverview.tripOverviewDescription",
              data.data.tripOverview.tripOverviewDescription
            );
            setFieldValue(
              "trip_overview_main_highlights",
              data.data.tripOverview.tripOverviewMainHighlights
                .map((item) => item)
                .join("\n")
            );
            setFieldValue("totalTravelCost", data.data.totalTravelCost);
            setFieldValue("dayWiseItinerary", data.data.dayWiseItinerary);
            setFieldValue(
              "tourHighlights",
              data.data.tourHighlights.map((item) => item).join("\n")
            );
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };

  const handleAddGalleryImage = () => {
    let err = "";
    if (!values.gallery_img) {
      err = "Gallery Image Is Require";
    }
    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      setFieldValue("imageGallery", [
        ...values.imageGallery,
        values.gallery_img,
      ]);
      setFieldValue("gallery_img", "");
      setFieldError("imageGallery", "");
      setFieldTouched("imageGallery", "");
    }
  };

  const upload = async (name, file) => {
    setLoading(true);
    const token = localStorage.getItem("admin_token");
    const formData = new FormData();
    formData.append("photo", file);
    fetch(ImageAPI, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setFieldValue(name, data?.url);
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
        setLoading(false);
      });
  };

  const handleUpdateTripOverviewMainHighlights = () => {
    let err = "";
    if (!values.tripOverview.tripOverviewDescription) {
      err = "Trip Overview Title Is Require";
    } else if (!values.trip_overview_main_highlights) {
      err = "Trip Overview Main Highlights Is Require";
    }

    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      const cpyData = [...values.tripOverview.tripOverviewMainHighlights];
      cpyData[travelItineraryEditIndex] = values.trip_overview_main_highlights;

      setFieldValue("tripOverview.tripOverviewMainHighlights", cpyData);
      setTravelItineraryEditIndex(null);
      setFieldValue("trip_overview_main_highlights", "");
    }
  };

  const handleAddTravelCost = () => {
    let err = "";
    if (!values.totalTravelCost.travelCostTitle) {
      err = "Cost Title Is Require";
    } else if (!values.category) {
      err = "Category Is Require";
    } else if (!values.minPrice) {
      err = "Minimum Price Is Require";
    } else if (!values.maxPrice) {
      err = "Maximum Price Is Require";
    } else if (!values.totalTravelCost.travelCostImage) {
      err = "Cost Image Is Require";
    }
    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      setFieldValue("totalTravelCost.travelCostData", [
        ...values.totalTravelCost.travelCostData,
        {
          category: values.category,
          minPrice: values.minPrice,
          maxPrice: values.maxPrice,
          note: values.note || "",
        },
      ]);
      setFieldValue("category", "");
      setFieldValue("minPrice", "");
      setFieldValue("maxPrice", "");
      setFieldValue("note", "");
      setFieldError("category", "");
      setFieldError("minPrice", "");
      setFieldError("maxPrice", "");
      setFieldTouched("totalTravelCost.travelCostData", false);
    }
  };

  const handleEditTravelCost = () => {
    let err = "";
    if (!values.category) {
      err = "Category Is Require";
    } else if (!values.minPrice) {
      err = "Minimum Price Is Require";
    } else if (!values.maxPrice) {
      err = "Maximum Price Is Require";
    }
    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      const cpyData = [...values.totalTravelCost.travelCostData];
      cpyData[travelCostIndex] = {
        category: values.category,
        minPrice: values.minPrice,
        maxPrice: values.maxPrice,
        note: values.note || "",
      };
      setFieldValue("totalTravelCost.travelCostData", cpyData);
      setIsEditTravelCost(false);
      setTravelCostIndex(null);
      setFieldValue("category", "");
      setFieldValue("minPrice", "");
      setFieldValue("maxPrice", "");
      setFieldValue("note", "");
      setFieldTouched("totalTravelCost.travelCostData", false);
    }
  };

  const handleAddDayWiseItinerary = () => {
    let err = "";
    if (!values.dayWiseItinerary.mainTitle) {
      err = "Main Title Is Require";
    } else if (!values.dayTitle) {
      err = "Day Title Is Require";
    } else if (!values.dayImage) {
      err = "Day Image Is Require";
    }

    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      setFieldValue("dayWiseItinerary", {
        ...values.dayWiseItinerary,
        mainTitle: values.dayWiseItinerary.mainTitle,
        dayWiseData: [
          ...values.dayWiseItinerary.dayWiseData,
          {
            dayTitle: values.dayTitle,
            morning: values.morning ? values.morning : [],
            afternoon: values.afternoon ? values.afternoon : [],
            evening: values.evening ? values.evening : [],
            night: values.night ? values.night : [],
            dayImage: values.dayImage,
            dayOptions: values.dayOptions.length > 0 ? values.dayOptions : [],
          },
        ],
      });
      setFieldValue("dayTitle", "");
      setFieldValue("morning", "");
      setFieldValue("afternoon", "");
      setFieldValue("evening", "");
      setFieldValue("night", "");
      setFieldValue("optionTitle", "");
      setFieldValue("optionData", "");
      setFieldValue("dayImage", "");
      setFieldTouched("dayWiseItinerary.dayWiseData", false);
    }
  };

  const handleEditDayWiseItinerary = () => {
    let err = "";
    if (!values.dayTitle) {
      err = "Day Title Is Require";
    } else if (!values.morning) {
      err = "Morning Itinerary Is Require";
    } else if (!values.afternoon) {
      err = "Afternoon Itinerary Is Require";
    } else if (!values.evening) {
      err = "Evening Itinerary Is Require";
    } else if (!values.night) {
      err = "Night Itinerary Is Require";
    }
    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      const cpyData = [...values.dayWiseItinerary.dayWiseData];
      cpyData[dayDetailsIndex] = {
        dayTitle: values.dayTitle,
        morning: values.morning ? values.morning : [],
        afternoon: values.afternoon ? values.afternoon : [],
        evening: values.evening ? values.evening : [],
        night: values.night ? values.night : [],
        dayImage: values.dayImage
          ? values.dayImage
          : cpyData[dayDetailsIndex].dayImage,
      };
      setFieldValue("dayWiseItinerary.dayWiseData", cpyData);
      setIsEditDayDetails(false);
      setDayDetailsIndex(null);
      setFieldValue("dayTitle", "");
      setFieldValue("morning", "");
      setFieldValue("afternoon", "");
      setFieldValue("evening", "");
      setFieldValue("night", "");
      setFieldValue("optionTitle", "");
      setFieldValue("optionData", "");
      setFieldTouched("dayWiseItinerary.dayWiseData", false);
    }
  };

  const handleAddDayWiseItineraryOptions = () => {
    if (values.optionTitle !== "" && values.optionData !== "") {
      setFieldValue("dayOptions", [
        ...values.dayOptions,
        {
          optionTitle: values.optionTitle,
          optionData: values.optionData,
        },
      ]);
      setFieldValue("optionTitle", "");
      setFieldValue("optionData", "");
    }
  };

  useEffect(() => {
    if (!ignore && travelItineraryId) {
      getSingleTravelItinerary();
    }

    return () => {
      ignore = true;
    };
  }, [travelItineraryId]);
  return (
    <>
      {loading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <div className="w-20">
            <Loading />
          </div>
        </div>
      ) : (
        <>
          <div className="travel-itinerary mt-10">
            <InputText
              type="text"
              id="travelItinerary"
              label="Travel Itinerary"
              name="travelItinerary"
              placeholder="Travel Itinerary Title"
              value={values?.travelItinerary ?? ""}
              onChange={handleChange}
              error={
                errors?.travelItinerary && touched?.travelItinerary
                  ? true
                  : false
              }
              errorText={errors?.travelItinerary}
            />
          </div>
          <div className="flex items-center justify-between mt-10">
            <div className="uppercase font-bold text-xl">gallery</div>
            <button
              onClick={handleAddGalleryImage}
              className="border-2 border-blue-500 text-blue-500 px-5 py-1 font-semibold rounded"
            >
              Add To Gallery
            </button>
          </div>
          {errors?.imageGallery && touched?.imageGallery && (
            <p className="text-red-500 text-sm">{errors?.imageGallery}</p>
          )}
          <div className="mt-8">
            <ImageUplaod
              value={values?.gallery_img ?? ""}
              onChange={(e) => {
                upload("gallery_img", e);
              }}
              label={"Gallery Image"}
            />
          </div>
          <div className="grid grid-cols-6 gap-5 mt-8">
            {values?.imageGallery?.map((img, mainIndex) => (
              <div key={mainIndex}>
                <div
                  onClick={() => {
                    setFieldValue(
                      "imageGallery",
                      values?.imageGallery?.filter(
                        (_, subIndex) => subIndex !== mainIndex
                      )
                    );
                  }}
                  className="border-2 cursor-pointer border-blue-500 flex items-center justify-center rounded-lg w-8 h-8"
                >
                  X
                </div>
                <img
                  src={img}
                  className="w-52 h-52 rounded-lg object-cover mt-2"
                  alt="gallery-img"
                />
              </div>
            ))}
          </div>

          <div className="uppercase font-bold text-xl mt-10">tour Overview</div>
          <div className="tour-overview-form mt-10 flex flex-col gap-5">
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="tourOverview.tourOverviewDuration"
                label="Tour Duration"
                name="tourOverview.tourOverviewDuration"
                placeholder="Tour Overview Duration"
                value={values?.tourOverview?.tourOverviewDuration ?? ""}
                onChange={handleChange}
                error={
                  errors?.tourOverview?.tourOverviewDuration &&
                  touched?.tourOverview?.tourOverviewDuration
                    ? true
                    : false
                }
                errorText={errors?.tourOverview?.tourOverviewDuration}
              />
              <InputText
                type="text"
                id="tourOverview.tourOverviewDescription"
                label="Tour Description"
                name="tourOverview.tourOverviewDescription"
                placeholder="Tour Overview Description"
                value={values?.tourOverview?.tourOverviewDescription ?? ""}
                onChange={handleChange}
                error={
                  errors?.tourOverview?.tourOverviewDescription &&
                  touched?.tourOverview?.tourOverviewDescription
                    ? true
                    : false
                }
                errorText={errors?.tourOverview?.tourOverviewDescription}
              />
            </div>
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="tourOverview.tourOverviewDestinations"
                label="Tour Destinations"
                name="tourOverview.tourOverviewDestinations"
                placeholder="Tour Overview Destinations"
                value={values?.tourOverview?.tourOverviewDestinations ?? ""}
                onChange={handleChange}
                error={
                  errors?.tourOverview?.tourOverviewDestinations &&
                  touched?.tourOverview?.tourOverviewDestinations
                    ? true
                    : false
                }
                errorText={errors?.tourOverview?.tourOverviewDestinations}
              />
              <InputText
                type="text"
                id="tourOverview.tourOverviewExclusions"
                label="Tour Exclusions"
                name="tourOverview.tourOverviewExclusions"
                placeholder="Tour Overview Exclusions"
                value={values?.tourOverview?.tourOverviewExclusions ?? ""}
                onChange={handleChange}
                error={
                  errors?.tourOverview?.tourOverviewExclusions &&
                  touched?.tourOverview?.tourOverviewExclusions
                    ? true
                    : false
                }
                errorText={errors?.tourOverview?.tourOverviewExclusions}
              />
            </div>
          </div>

          <div className="flex items-center justify-between mt-10">
            <div className="uppercase font-bold text-xl">trip Overview</div>
            {travelItineraryId && (
              <button
                onClick={() => handleUpdateTripOverviewMainHighlights()}
                className="border-2 border-blue-500 text-blue-500 px-5 py-1 font-semibold rounded"
              >
                Edit Highlight
              </button>
            )}
          </div>
          <div className="tour-overview-form mt-10 flex flex-col gap-5">
            <InputText
              type="text"
              id="tripOverview.tripOverviewDescription"
              label="Trip Title"
              name="tripOverview.tripOverviewDescription"
              placeholder="Trip Overview Title"
              value={values?.tripOverview?.tripOverviewDescription ?? ""}
              onChange={handleChange}
              error={
                errors?.tripOverview?.tripOverviewDescription &&
                touched?.tripOverview?.tripOverviewDescription
                  ? true
                  : false
              }
              errorText={errors?.tripOverview?.tripOverviewDescription}
            />
            <MDInput
              id="trip_overview_main_highlights"
              label="Trip Main Highlights"
              value={values?.trip_overview_main_highlights}
              onChange={(value) => {
                setFieldValue("trip_overview_main_highlights", value);
              }}
              className="mt-2"
              error={
                errors?.trip_overview_main_highlights &&
                touched?.trip_overview_main_highlights
                  ? true
                  : false
              }
              errorText={errors?.trip_overview_main_highlights}
            />
          </div>

          <div className="flex items-center justify-between mt-10">
            <div className="uppercase font-bold text-xl">
              total cost of travel
            </div>
            <button
              onClick={() => {
                isEditTravelCost
                  ? handleEditTravelCost()
                  : handleAddTravelCost();
              }}
              className="border-2 border-blue-500 text-blue-500 px-5 py-1 font-semibold rounded"
            >
              {isEditTravelCost ? "Edit Cost" : "Add Cost"}
            </button>
          </div>
          {errors?.totalTravelCost?.travelCostData &&
            touched?.totalTravelCost?.travelCostData && (
              <p className="text-red-500 text-sm">
                {errors?.totalTravelCost?.travelCostData}
              </p>
            )}
          <div className="tour-overview-form mt-10 flex flex-col gap-5">
            <InputText
              type="text"
              id="totalTravelCost.travelCostTitle"
              label="Cost Title"
              name="totalTravelCost.travelCostTitle"
              placeholder="Travel Cost Title"
              value={values?.totalTravelCost?.travelCostTitle ?? ""}
              onChange={handleChange}
              error={
                errors?.totalTravelCost?.travelCostTitle &&
                touched?.totalTravelCost?.travelCostTitle
                  ? true
                  : false
              }
              errorText={errors?.totalTravelCost?.travelCostTitle}
            />
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="category"
                label="Category"
                name="category"
                placeholder="Enter Category"
                value={values?.category ?? ""}
                onChange={handleChange}
                error={errors?.category && touched?.category ? true : false}
                errorText={errors?.category}
              />
              <InputText
                type="number"
                id="minPrice"
                label="Min Price"
                name="minPrice"
                placeholder="Minimum cost"
                value={values?.minPrice ?? ""}
                onChange={handleChange}
                error={errors?.minPrice && touched?.minPrice ? true : false}
                errorText={errors?.minPrice}
              />
            </div>
            <div className="flex w-full gap-5">
              <InputText
                type="number"
                id="maxPrice"
                label="Max Price"
                name="maxPrice"
                placeholder="Maximum cost"
                value={values?.maxPrice ?? ""}
                onChange={handleChange}
                error={errors?.maxPrice && touched?.maxPrice ? true : false}
                errorText={errors?.maxPrice}
              />
              <InputText
                type="text"
                id="note"
                label="Note"
                name="note"
                placeholder="Cost note"
                value={values?.note ?? ""}
                onChange={handleChange}
              />
            </div>
            <div className="img-box">
              <ImageUplaod
                value={values.totalTravelCost.travelCostImage ?? ""}
                onChange={(e) => {
                  upload("totalTravelCost.travelCostImage", e);
                }}
                label={"Cost Image"}
              />
              {errors?.totalTravelCost?.travelCostImage &&
                touched?.totalTravelCost?.travelCostImage && (
                  <p className="text-red-500 text-sm">
                    {errors?.totalTravelCost?.travelCostImage}
                  </p>
                )}
            </div>
          </div>
          <div className="mt-8 overflow-x-auto destination-nav rounded-tl-lg rounded-tr-lg">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Category
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    min Price
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Max price
                  </th>
                  <th className="px-5 w-1/5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    note
                  </th>
                  <th className="px-5 w-1/5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {values?.totalTravelCost?.travelCostData?.length > 0 ? (
                  values?.totalTravelCost?.travelCostData?.map(
                    (cost, index) => (
                      <tr key={index}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                          <p className="text-gray-900 line-clamp-2 break-all whitespace-normal capitalize">
                            {cost?.category}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                          <p className="text-gray-900 line-clamp-2 break-all whitespace-normal capitalize">
                            {cost?.minPrice}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                          <p className="text-gray-900 line-clamp-2 break-all whitespace-normal capitalize">
                            {cost?.maxPrice}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                          <p className="text-gray-900 line-clamp-2 w-20 xl:w-80 break-all whitespace-normal capitalize">
                            {cost?.note ?? "-"}
                          </p>
                        </td>
                        <td className="px-5 py-5 w-1/5 border-b border-gray-200 bg-white text-sm">
                          <div
                            onClick={() => {
                              setIsEditTravelCost(true);
                              setTravelCostIndex(index);
                              setFieldValue("category", cost?.category);
                              setFieldValue("minPrice", cost?.minPrice);
                              setFieldValue("maxPrice", cost?.maxPrice);
                              setFieldValue("note", cost?.note);
                            }}
                            className="border-2 cursor-pointer border-blue-500 flex items-center justify-center rounded-lg w-8 h-8"
                          >
                            <PencilIcon className="h-5 w-5" />
                          </div>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={5} className="px-5 py-5  bg-white text-sm">
                      <div className="w-full text-gray-900 capitalize flex justify-center">
                        No cost data found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex items-center justify-between mt-10">
            <div className="uppercase font-bold text-xl">
              day wise Itinerary
            </div>
            <button
              onClick={() => {
                isEditDayDetails
                  ? handleEditDayWiseItinerary()
                  : handleAddDayWiseItinerary();
              }}
              className="border-2 border-blue-500 text-blue-500 px-5 py-1 font-semibold rounded"
            >
              {isEditDayDetails ? "Edit Details" : "Add Details"}
            </button>
          </div>
          {errors?.dayWiseItinerary?.dayWiseData &&
            touched?.dayWiseItinerary?.dayWiseData && (
              <p className="text-red-500 text-sm">
                {errors?.dayWiseItinerary?.dayWiseData}
              </p>
            )}
          <div className="day-wise-form mt-10 flex flex-col gap-5">
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="dayWiseItinerary.mainTitle"
                label="Main Title"
                name="dayWiseItinerary.mainTitle"
                placeholder="Main title"
                value={values?.dayWiseItinerary?.mainTitle ?? ""}
                onChange={handleChange}
                error={
                  errors?.dayWiseItinerary?.mainTitle &&
                  touched?.dayWiseItinerary?.mainTitle
                    ? true
                    : false
                }
                errorText={errors?.dayWiseItinerary?.mainTitle}
              />
              <InputText
                type="text"
                id="dayTitle"
                label="Day Title"
                name="dayTitle"
                placeholder="Day title"
                value={values?.dayTitle ?? ""}
                onChange={handleChange}
              />
            </div>
            <div className="flex w-full gap-5">
              <div className="editor flex flex-col w-full gap-5">
                <MDInput
                  id="morning"
                  label="Morning Points"
                  value={values?.morning ?? ""}
                  onChange={(value) => {
                    setFieldValue("morning", value);
                  }}
                  className="mt-2"
                />
                <MDInput
                  id="evening"
                  label="Evening Points"
                  value={values?.evening ?? ""}
                  onChange={(value) => {
                    setFieldValue("evening", value);
                  }}
                  className="mt-2"
                />
              </div>
              <div className="editor flex flex-col w-full gap-5">
                <MDInput
                  id="afternoon"
                  label="Afternoon Points"
                  value={values?.afternoon ?? ""}
                  onChange={(value) => {
                    setFieldValue("afternoon", value);
                  }}
                  className="mt-2"
                />
                <MDInput
                  id="night"
                  label="Night Points"
                  value={values?.night ?? ""}
                  onChange={(value) => {
                    setFieldValue("night", value);
                  }}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="option-content flex flex-col gap-5">
              <button
                onClick={() => handleAddDayWiseItineraryOptions()}
                className="border-2 border-blue-500 text-blue-500 px-5 py-1 font-semibold rounded w-fit ms-auto"
              >
                Add Options
              </button>
              <InputText
                type="text"
                id="optionTitle"
                label="Option Title"
                name="optionTitle"
                placeholder="Option Title"
                value={values?.optionTitle ?? ""}
                onChange={handleChange}
              />
              <MDInput
                id="optionData"
                label="Options Data Points"
                value={values?.optionData}
                onChange={(value) => {
                  setFieldValue("optionData", value);
                }}
                className="mt-2"
              />
            </div>
            {errors.gallery && touched.gallery && (
              <p className="text-red-500 text-sm">{errors.gallery}</p>
            )}
            <ImageUplaod
              value={values.dayImage ?? ""}
              onChange={(e) => {
                upload("dayImage", e);
              }}
              label="Day Cover Image"
            />
          </div>
          <div className="mt-8 overflow-x-auto destination-nav rounded-tl-lg rounded-tr-lg">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Image
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Day Title
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    morning points
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    afternoon points
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    evening points
                  </th>
                  <th className="px-5 w-1/5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    night points
                  </th>
                  <th className="px-5 w-1/5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {values?.dayWiseItinerary?.dayWiseData?.length > 0 ? (
                  values?.dayWiseItinerary?.dayWiseData?.map((data, index) => (
                    <tr key={index}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={data.dayImage}
                          alt="day-img"
                        />
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                        <p className="text-gray-900 line-clamp-2 break-all whitespace-normal capitalize">
                          {data?.dayTitle}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                        <p className="text-gray-900 line-clamp-2 break-all whitespace-normal capitalize">
                          {data?.morning}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                        <p className="text-gray-900 line-clamp-2 break-all whitespace-normal capitalize">
                          {data?.afternoon}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                        <p className="text-gray-900 line-clamp-2 break-all whitespace-normal capitalize">
                          {data?.evening}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                        <p className="text-gray-900 line-clamp-2 w-20 xl:w-80 break-all whitespace-normal capitalize">
                          {data?.night ?? "-"}
                        </p>
                      </td>
                      <td className="px-5 py-5 w-1/5 border-b border-gray-200 bg-white text-sm">
                        <div
                          onClick={() => {
                            setIsEditDayDetails(true);
                            setDayDetailsIndex(index);
                            setFieldValue("dayTitle", data?.dayTitle);
                            setFieldValue(
                              "morning",
                              travelItineraryId
                                ? data?.morning?.map((item) => item).join("\n")
                                : data?.morning
                            );
                            setFieldValue(
                              "afternoon",
                              travelItineraryId
                                ? data?.afternoon
                                    ?.map((item) => item)
                                    .join("\n")
                                : data?.afternoon
                            );
                            setFieldValue(
                              "evening",
                              travelItineraryId
                                ? data?.evening?.map((item) => item).join("\n")
                                : data?.evening
                            );
                            setFieldValue(
                              "night",
                              travelItineraryId
                                ? data?.night?.map((item) => item).join("\n")
                                : data?.night
                            );
                          }}
                          className="border-2 cursor-pointer border-blue-500 flex items-center justify-center rounded-lg w-8 h-8"
                        >
                          <PencilIcon className="h-5 w-5" />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="px-5 py-5  bg-white text-sm">
                      <div className="w-full text-gray-900 capitalize flex justify-center">
                        No day wise data found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="uppercase font-bold text-xl mt-10">
            tour Highlights
          </div>
          <div className="editor w-full mt-10">
            <MDInput
              id="tourHighlights"
              label="Tour Highlights"
              value={values?.tourHighlights}
              onChange={(value) => {
                setFieldValue("tourHighlights", value);
              }}
              className="mt-2"
              error={
                errors?.tourHighlights && touched?.tourHighlights ? true : false
              }
              errorText={errors?.tourHighlights}
            />
          </div>

          <div className="flex mt-5 gap-5">
            <button
              onClick={() => handleSubmit()}
              type="submit"
              className="text-center w-full bg-blue-400 gap-2 items-center hover:bg-blue-500 text-white font-bold py-[9px] px-4 rounded"
            >
              Save
            </button>
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="text-center w-full border border-blue-400 gap-2 items-center text-blue-500 font-bold py-[9px] px-4 rounded"
            >
              Cancel
            </button>
          </div>
        </>
      )}

      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </>
  );
};

export default AdminTravelItineraryForm;
