import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import ImageUplaod from "../Components/ImageUplaod";
import InputText from "../Components/InputText";
import TextAreaInput from "../Components/TextArea";
import CreatableInput from "../Components/CreatableInput";
import FloatNotification from "../Components/FloatNotification";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageAPI, TourListAPI } from "../API";
import MDInput from "./MDInput";
import Loading from "./Loading";
import {
  PencilIcon,
  XMarkIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/outline";

const AdminDestinationForm = ({
  addDestination,
  updateDestination,
  packageId,
  API,
  loading,
  setLoading,
}) => {
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [isTourPlanEdit, setIsTourPlanEdit] = useState(false);
  const [tourPlanEditIndex, setTourPlanEditIndex] = useState(null);
  let ignore = false;
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      information: {
        destination_name: "",
        destination_title: "",
        age: "",
        review: "",
        destination_country: "",
        departure: "",
        departure_time: "",
        days: "",
        night: "",
        peoples: "",
        return_time: "",
        dress_code: "",
        what_include: [],
        description: "",
      },
      outer_banner: "",
      banner_img: "",
      gallery_img: "",
      review_img: "",
      tourplan: [],
      plan_name: "",
      include: "",
      exclude: "",
      description: "",
      review_name: "",
      review_description: "",
      gallery: [],
      review: [],
    },
    validationSchema: yup.object().shape({
      information: yup.object().shape({
        destination_name: yup.string().required("Destination Name Required"),
        destination_title: yup.string().required("Destination Title Required"),
        age: yup.string().required("Age Required"),
        review: yup.string().required("Review Required"),
        destination_country: yup.string().required("Destination Required"),
        departure: yup.string().required("Departure Required"),
        departure_time: yup.string().required("Departure Time Required"),
        days: yup.string().required("Day Required"),
        night: yup.string().required("Night Required"),
        peoples: yup.string().required("People's Required"),
        return_time: yup.string().required("Return Time Required"),
        dress_code: yup.string().required("Dress Code Required"),
        destination_country: yup
          .string()
          .required("Destination Country Required"),
        what_include: yup
          .array()
          .min(1, "At Least One Item Needs To Be Here")
          .required("At Least One Item Needs To Be Here"),
        description: yup.string().required("Description Required"),
        tourplan: yup.array().min(1, "Please Add Minimum 1 Tour PLan"),
      }),
      tourplan: yup
        .array()
        .min(1, "Please Add minimum 1 Day")
        .required("Tour Plan Is Require"),
      gallery: yup
        .array()
        .min(1, "Please Add minimum 1 Image To Gallery")
        .required("Gallery Is Require"),
      review: yup
        .array()
        .min(1, "Please Add minimum 1 Review")
        .required("Review Is Require"),
      banner_img: yup.string().required("Banner Image Is Required"),
      outer_banner: yup.string().required("Outer Banner Image Is Required"),
    }),
    onSubmit: (value, errors) => {
      location.pathname.includes("add")
        ? addDestination(values, resetForm, setShowNotification)
        : updateDestination(values, resetForm, setShowNotification);
    },
  });
  const handleAddPlan = () => {
    let err = "";
    if (!values.plan_name) {
      err = "Plan Name Is Require";
    } else if (!values.description) {
      err = "Description Require";
    }
    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      const isPlan = values.tourplan.find(
        (plan) => plan.plan_name === values.plan_name
      );
      if (isPlan) {
        setShowNotification({
          show: true,
          message: <div>{values.plan_name} Is Alredy Exist</div>,
          title: "Validation Error",
          status: "failed",
        });
      } else {
        setFieldValue("tourplan", [
          ...values.tourplan,
          {
            plan_name: values.plan_name,
            description: values.description,
          },
        ]);
        setFieldValue("plan_name", "");
        setFieldValue("description", "");
        setFieldError("tourplan", "");
      }
    }
  };
  const handleUpdatePlan = () => {
    let err = "";
    if (!values.plan_name) {
      err = "Plan Name Is Require";
    } else if (!values.description) {
      err = "Description Require";
    }
    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      const isPlan = values.tourplan.find(
        (plan) => plan.plan_name === values.plan_name
      );
      const index = values.tourplan.findIndex(
        (plan) => plan.plan_name === values.plan_name
      );
      if (isPlan && index !== tourPlanEditIndex) {
        setShowNotification({
          show: true,
          message: <div>{values.plan_name} Is Alredy Exist</div>,
          title: "Validation Error",
          status: "failed",
        });
      } else {
        const cpyData = [...values.tourplan];
        cpyData[tourPlanEditIndex].plan_name = values.plan_name;
        cpyData[tourPlanEditIndex].description = values.description;
        setFieldValue("tourplan", cpyData);
        setFieldValue("plan_name", "");
        setFieldValue("description", "");
        setFieldError("tourplan", "");
        setIsTourPlanEdit(false);
        setTourPlanEditIndex(null);
      }
    }
  };
  const handleAddGallery = () => {
    let err = "";
    if (!values.gallery_img) {
      err = "Gallery Image Is Require";
    }
    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      setFieldValue("gallery", [
        ...values.gallery,
        {
          gallery_img: values.gallery_img,
        },
      ]);
      setFieldValue("gallery_img", "");
      setFieldError("gallery", "");
    }
  };
  const handleAddReview = () => {
    let err = "";
    if (!values.review_img) {
      err = "Review Image Is Require";
    } else if (!values.review_name) {
      err = "Review Name Require";
    } else if (!values.review_description) {
      err = "Review Description Require";
    }
    if (err) {
      setShowNotification({
        show: true,
        message: <div>{err}</div>,
        title: "Validation Error",
        status: "failed",
      });
    } else {
      const isReview = values.review.find(
        (review) => review.review_name === values.review_name
      );
      if (isReview) {
        setShowNotification({
          show: true,
          message: <div>{values.review_name} Is Already Exist</div>,
          title: "Validation Error",
          status: "failed",
        });
      } else {
        setFieldValue("review", [
          ...values.review,
          {
            review_name: values.review_name,
            review_description: values.review_description,
            review_img: values.review_img,
          },
        ]);
        setFieldValue("review_name", "");
        setFieldValue("review_description", "");
        setFieldValue("review_img", "");
        setFieldError("review", "");
      }
    }
  };

  const upload = async (name, file) => {
    setLoading(true);
    const token = localStorage.getItem("admin_token");
    const formData = new FormData();
    formData.append("photo", file);
    fetch(ImageAPI, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setFieldValue(name, data?.url);
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
        setLoading(false);
      });
  };
  const getPakageById = () => {
    const token = localStorage.getItem("admin_token");
    setLoading(true);
    if (token) {
      fetch(`${API}/get-by-id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ _id: packageId }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            let value = {
              banner_img: data.data.banner_img,
              outer_banner: data.data.outer_banner,
              information: {
                ...data.data.information,
                what_include: data.data.information.what_include.map((item) => {
                  return { label: item, value: item };
                }),
              },
              tourplan: data.data.tourplan,
              gallery: data.data.gallery.map((item) => {
                return { gallery_img: item };
              }),
              review: data.data.review,
              include: data.data.include,
              exclude: data.data.exclude,
            };
            setFieldValue("information", value.information);
            setFieldValue("banner_img", value.banner_img);
            setFieldValue("outer_banner", value.outer_banner);
            setFieldValue("tourplan", value.tourplan);
            setFieldValue("gallery", value.gallery);
            setFieldValue("review", value.review);
            setFieldValue("include", value.include);
            setFieldValue("exclude", value.exclude);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (!ignore && packageId) {
      getPakageById();
    }

    return () => {
      ignore = true;
    };
  }, [packageId]);
  const movePlan = (array, to, from) => {
    const item = array[from];
    array.splice(from, 1);
    array.splice(to, 0, item);
    return array;
  };
  return (
    <div>
      {loading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <div className="w-20">
            <Loading />
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-8 flex gap-5">
            <div className="w-1/5 h-80">
              <ImageUplaod
                value={values.outer_banner ?? ""}
                onChange={(e) => {
                  upload("outer_banner", e);
                }}
                label={"Tour Outer Banner"}
                error={
                  errors?.outer_banner && touched?.outer_banner ? true : false
                }
                errorText={errors.outer_banner}
              />
            </div>
            <div className="w-4/5 h-80">
              <ImageUplaod
                value={values.banner_img ?? ""}
                onChange={(e) => {
                  upload("banner_img", e);
                }}
                label={"Tour Image"}
                error={errors?.banner_img && touched?.banner_img ? true : false}
                errorText={errors.banner_img}
              />
            </div>
          </div>
          <div className="uppercase font-bold text-xl mt-10">information</div>
          <div className="mt-10 flex flex-col gap-5">
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="information.destination_name"
                label="Destination Name"
                name="information.destination_name"
                placeholder="Destination Name"
                value={values?.information?.destination_name ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.destination_name &&
                  touched?.information?.destination_name
                    ? true
                    : false
                }
                errorText={errors?.information?.destination_name}
              />
              <InputText
                type="text"
                id="information.destination_title"
                label="Destination Title"
                name="information.destination_title"
                placeholder="Destination Title"
                value={values?.information?.destination_title ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.destination_title &&
                  touched?.information?.destination_title
                    ? true
                    : false
                }
                errorText={errors?.information?.destination_title}
              />
            </div>
            <div className="flex w-full gap-5">
              <InputText
                type="number"
                id="information.review"
                label="Review"
                name="information.review"
                placeholder="Review"
                value={values?.information?.review ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.review && touched?.information?.review
                    ? true
                    : false
                }
                errorText={errors?.information?.review}
              />
              <InputText
                type="text"
                id="information.age"
                label="Age"
                name="information.age"
                placeholder="Age"
                value={values?.information?.age ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.age && touched?.information?.age
                    ? true
                    : false
                }
                errorText={errors?.information?.age}
              />
            </div>
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="information.destination_country"
                label="Destination Country"
                name="information.destination_country"
                placeholder="Destination Country"
                value={values?.information?.destination_country ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.destination_country &&
                  touched?.information?.destination_country
                    ? true
                    : false
                }
                errorText={errors?.information?.destination_country}
              />
              <InputText
                type="text"
                id="information.departure"
                label="Departure"
                name="information.departure"
                placeholder="Departure"
                value={values?.information?.departure ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.departure &&
                  touched?.information?.departure
                    ? true
                    : false
                }
                errorText={errors?.information?.departure}
              />
            </div>
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="information.departure_time"
                label="Departure Time"
                name="information.departure_time"
                placeholder="Departure Time"
                value={values?.information?.departure_time ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.departure_time &&
                  touched?.information?.departure_time
                    ? true
                    : false
                }
                errorText={errors?.information?.departure_time}
              />
              <InputText
                type="text"
                id="information.days"
                label="Days"
                name="information.days"
                placeholder="Days"
                value={values?.information?.days ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.days && touched?.information?.days
                    ? true
                    : false
                }
                errorText={errors?.information?.days}
              />
            </div>
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="information.night"
                label="Night"
                name="information.night"
                placeholder="Night"
                value={values?.information?.night ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.night && touched?.information?.night
                    ? true
                    : false
                }
                errorText={errors?.information?.night}
              />
              <InputText
                type="text"
                id="information.peoples"
                label="Peoples"
                name="information.peoples"
                placeholder="Peoples"
                value={values?.information?.peoples ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.peoples && touched?.information?.peoples
                    ? true
                    : false
                }
                errorText={errors?.information?.peoples}
              />
            </div>
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="information.return_time"
                label="Return Time"
                name="information.return_time"
                placeholder="Return Time"
                value={values?.information?.return_time ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.return_time &&
                  touched?.information?.return_time
                    ? true
                    : false
                }
                errorText={errors?.information?.return_time}
              />
              <InputText
                type="text"
                id="information.dress_code"
                label="Dress Code"
                name="information.dress_code"
                placeholder="Dress Code"
                value={values?.information?.dress_code ?? ""}
                onChange={handleChange}
                error={
                  errors?.information?.dress_code &&
                  touched?.information?.dress_code
                    ? true
                    : false
                }
                errorText={errors?.information?.dress_code}
              />
            </div>
            <div className="flex w-full gap-5">
              <div className="w-full">
                <CreatableInput
                  id="information.what_include"
                  label="What Include"
                  name="information.what_include"
                  placeholder="What Is Include"
                  value={values?.information?.what_include}
                  setValue={setFieldValue}
                  error={
                    errors?.information?.what_include &&
                    touched?.information?.what_include
                      ? true
                      : false
                  }
                  errorText={errors?.information?.what_include}
                />
              </div>
            </div>
            <MDInput
              id="information.description"
              label="Description"
              value={values?.information?.description}
              onChange={(value) => {
                setFieldValue("information.description", value);
              }}
              className="mt-2"
              error={
                errors?.information?.description &&
                touched?.information?.description
                  ? true
                  : false
              }
              errorText={errors?.information?.description}
            />
          </div>
          <div className="flex items-center justify-between mt-10">
            <div className="uppercase font-bold text-xl">tour plan</div>
            <button
              onClick={() => {
                isTourPlanEdit ? handleUpdatePlan() : handleAddPlan();
              }}
              className="border-2 border-blue-500 text-blue-500 px-5 py-1 font-semibold rounded"
            >
              {isTourPlanEdit ? "Edit Plan" : "Add Plan"}
            </button>
          </div>
          {errors.tourplan && touched.tourplan && (
            <p className="text-red-500 text-sm">{errors.tourplan}</p>
          )}
          <div className="mt-10 flex flex-col gap-5">
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="plan_name"
                label="Plan Name"
                name="plan_name"
                placeholder="Plan Name"
                value={values?.plan_name ?? ""}
                onChange={handleChange}
              />
            </div>
            <MDInput
              id="description"
              label="Description"
              value={values?.description}
              onChange={(value) => {
                setFieldValue("description", value);
              }}
              className="mt-2"
            />
          </div>
          <div className="mt-8 overflow-x-auto destination-nav rounded-tl-lg rounded-tr-lg">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 rounded-tl-lg border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize w-1/5">
                    Change Index
                  </th>
                  <th className="px-5 py-3 border-b-2 rounded-tl-lg border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize w-1/5">
                    Plan Name
                  </th>
                  <th className="px-5 w-1/5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Description
                  </th>
                  <th className="px-5 w-1/5 py-3 border-b-2 rounded-tr-lg border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {values.tourplan.length > 0 ? (
                  values.tourplan.map((plan, index) => (
                    <tr key={index}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                        <div className="flex gap-2">
                          {index !== values.tourplan.length - 1 && (
                            <ArrowDownIcon
                              onClick={() => {
                                const updatedPlan = movePlan(
                                  values.tourplan,
                                  index + 1,
                                  index
                                );
                                setFieldValue("tourplan", updatedPlan);
                              }}
                              className="h-5 w-5 cursor-pointer"
                            />
                          )}
                          {index !== 0 && (
                            <ArrowUpIcon
                              onClick={() => {
                                const updatedPlan = movePlan(
                                  values.tourplan,
                                  index - 1,
                                  index
                                );
                                setFieldValue("tourplan", updatedPlan);
                              }}
                              className="h-5 w-5 cursor-pointer"
                            />
                          )}
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                        <p className="text-gray-900 whitespace-normal capitalize">
                          {plan.plan_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5">
                        <p className="text-gray-900 line-clamp-2 w-20 xl:w-80 break-all whitespace-normal capitalize">
                          {plan.description}
                        </p>
                      </td>
                      <td className="px-5 py-5 w-1/5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex gap-5">
                          <div
                            onClick={() => {
                              setFieldValue(
                                "tourplan",
                                values.tourplan.filter(
                                  (plans) => plans.plan_name !== plan.plan_name
                                )
                              );
                            }}
                            className="border-2 cursor-pointer border-blue-500 flex items-center justify-center rounded-lg w-8 h-8"
                          >
                            <XMarkIcon className="h-5 w-5" />
                          </div>
                          <div
                            onClick={() => {
                              setIsTourPlanEdit(true);
                              setTourPlanEditIndex(index);
                              setFieldValue("plan_name", plan.plan_name);
                              setFieldValue("description", plan.description);
                            }}
                            className="border-2 cursor-pointer border-blue-500 flex items-center justify-center rounded-lg w-8 h-8"
                          >
                            <PencilIcon className="h-5 w-5" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="px-5 py-5  bg-white text-sm">
                      <div className="w-full text-gray-900 capitalize flex justify-center">
                        No Data Found In Tour Plan
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-between mt-10">
            <div className="uppercase font-bold text-xl">gallery</div>
            <button
              onClick={handleAddGallery}
              className="border-2 border-blue-500 text-blue-500 px-5 py-1 font-semibold rounded"
            >
              Add To Gallery
            </button>
          </div>
          {errors.gallery && touched.gallery && (
            <p className="text-red-500 text-sm">{errors.gallery}</p>
          )}
          <div className="mt-8">
            <ImageUplaod
              value={values.gallery_img ?? ""}
              onChange={(e) => {
                upload("gallery_img", e);
              }}
              label={"Gallery Image"}
            />
          </div>
          <div className="grid grid-cols-3 gap-5 mt-8">
            {values.gallery.map((img, ind) => (
              <div key={ind}>
                <div
                  onClick={() => {
                    setFieldValue(
                      "gallery",
                      values.gallery.filter(
                        (image) => image.gallery_img !== img.gallery_img
                      )
                    );
                  }}
                  className="border-2 cursor-pointer border-blue-500 flex items-center justify-center rounded-lg w-8 h-8"
                >
                  X
                </div>
                <img
                  src={img.gallery_img}
                  className="w-52 h-52 rounded-lg object-cover mt-2"
                />
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between mt-10">
            <div className="uppercase font-bold text-xl">Review</div>
            <button
              onClick={handleAddReview}
              className="border-2 border-blue-500 text-blue-500 px-5 py-1 font-semibold rounded"
            >
              Add Review
            </button>
          </div>
          {errors.review && touched.review && (
            <p className="text-red-500 text-sm">{errors.review}</p>
          )}
          <div className="mt-8">
            <ImageUplaod
              value={values.review_img ?? ""}
              onChange={(e) => {
                upload("review_img", e);
              }}
              label={"Review Image"}
            />
          </div>
          <div className="flex w-full gap-5 mt-8">
            <InputText
              type="text"
              id="review_name"
              label="Name"
              name="review_name"
              placeholder="Name"
              value={values?.review_name ?? ""}
              onChange={handleChange}
            />
          </div>
          <div className="mt-8">
            <MDInput
              id="review_description"
              label="Description"
              value={values?.review_description}
              onChange={(value) => {
                setFieldValue("review_description", value);
              }}
              className="mt-2"
            />
          </div>
          <div className="mt-8 overflow-x-auto destination-nav rounded-tl-lg rounded-tr-lg">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 rounded-tl-lg border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Review Image
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Description
                  </th>
                  <th className="px-5 py-3 border-b-2 rounded-tr-lg border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {values.review.length > 0 ? (
                  values.review.map((review, index) => (
                    <tr key={index}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={review.review_img}
                          alt={review.return_time}
                        />
                      </td>
                      <td className="px-5 w-52 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap capitalize">
                          {review.review_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 line-clamp-2 w-20 xl:w-80 break-all whitespace-normal capitalize">
                          {review.review_description}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div
                          onClick={() => {
                            setFieldValue(
                              "review",
                              values.review.filter(
                                (reviews) =>
                                  reviews.review_name !== review.review_name
                              )
                            );
                          }}
                          className="border-2 cursor-pointer border-blue-500 flex items-center justify-center rounded-lg w-8 h-8"
                        >
                          X
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="px-5 py-5  bg-white text-sm">
                      <div className="w-full text-gray-900 capitalize flex justify-center">
                        No Data Found In Review
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-8">
            <MDInput
              id="include"
              label="What Include"
              value={values?.include}
              onChange={(value) => {
                setFieldValue("include", value);
              }}
              className="mt-2"
            />{" "}
          </div>
          <div className="mt-8">
            <MDInput
              id="exclude"
              label="What Exclude"
              value={values?.exclude}
              onChange={(value) => {
                setFieldValue("exclude", value);
              }}
              className="mt-2"
            />
          </div>
          <div className="flex mt-5 gap-5 ">
            <button
              onClick={() => handleSubmit()}
              type="submit"
              className="text-center w-full bg-blue-400 gap-2 items-center hover:bg-blue-500 text-white font-bold py-[9px] px-4 rounded"
            >
              Save
            </button>
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="text-center w-full border border-blue-400 gap-2 items-center text-blue-500 font-bold py-[9px] px-4 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default AdminDestinationForm;
