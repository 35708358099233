import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogOutModel from "../Components/LogoutModel";
import logo from "../images/logo.png";
import {
  ArrowRightStartOnRectangleIcon,
  Bars3CenterLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  HomeIcon,
  ListBulletIcon,
  XCircleIcon,
  MapIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  IconAddressBook,
  IconArticle,
  IconBrandBlogger,
  IconBubble,
  IconHearts,
  IconHome2,
  IconMessage,
  IconPlane,
  IconUser,
  IconZzz,
} from "@tabler/icons-react";
import Notification from "../Components/Notification";

const AdminLayout = ({ children }) => {
  const location = useLocation();
  let route = activeRout.find((item) =>
    item.links.includes(location.pathname.split("/")[3]) ? true : false
  );
  const navigate = useNavigate();
  const [showSideBar, setShowSideBar] = useState(false);
  const [logOutModel, setLogOutModel] = useState(false);
  const [selectedTab, setSelectedTab] = useState(route.name);
  const [showDrawer, setShowDrawer] = useState(false);
  let ignore = false;
  const routes = [
    {
      name: "Dashboard",
      activeName: undefined,
      icon: HomeIcon,
      link: "/super_admin/dashboard",
    },
    {
      name: "Home Page",
      activeName: "home-page",
      icon: IconHome2,
      link: "/super_admin/dashboard/home-page",
      sub_menu: [
        {
          name: "Page Content",
          activeName: "home-page",
          icon: IconHome2,
          link: "/super_admin/dashboard/home-page",
        },
        {
          name: "Favourite Destination",
          activeName: "favourite-destination",
          icon: IconHearts,
          link: "/super_admin/dashboard/favourite-destination",
        },
        {
          name: "Travel Gallery",
          activeName: "travel-gallery",
          icon: IconZzz,
          link: "/super_admin/dashboard/travel-gallery",
        },
        {
          name: "Testimonial",
          activeName: "testimonial",
          icon: IconMessage,
          link: "/super_admin/dashboard/testimonial",
        },
      ],
    },
    {
      name: "Tour List Page",
      activeName: "tour-list-page",
      icon: IconPlane,
      link: "/super_admin/dashboard/tour-list-page",
      sub_menu: [
        {
          name: "Page Content",
          activeName: "tour-list-page",
          icon: IconPlane,
          link: "/super_admin/dashboard/tour-list-page",
        },
        {
          name: "Top Destination",
          activeName: "top-destination",
          icon: ListBulletIcon,
          link: "/super_admin/dashboard/top-destination",
        },
      ],
    },
    {
      name: "Travel Itinerary",
      activeName: "travel-itinerary",
      icon: MapIcon,
      link: "/super_admin/dashboard/travel-itinerary",
      sub_menu: [
        {
          name: "View Travel Itinerary",
          activeName: "travel-itinerary",
          icon: ListBulletIcon,
          link: "/super_admin/dashboard/travel-itinerary",
        },
        {
          name: "Add Travel Itinerary",
          activeName: "travel-itinerary-add",
          icon: PlusIcon,
          link: "/super_admin/dashboard/travel-itinerary-add",
        },
      ],
    },
    {
      name: "Blog Page",
      activeName: "blog-page",
      icon: IconBrandBlogger,
      link: "/super_admin/dashboard/blog-page",
      sub_menu: [
        {
          name: "Page Content",
          activeName: "blog-page",
          icon: IconBrandBlogger,
          link: "/super_admin/dashboard/blog-page",
        },
        {
          name: "Blog Post",
          activeName: "blog-post",
          icon: IconArticle,
          link: "/super_admin/dashboard/blog-post",
        },
        {
          name: "Blog Comment",
          activeName: "blog-comment",
          icon: IconBubble,
          link: "/super_admin/dashboard/blog-comment",
        },
      ],
    },
    {
      name: "Contact Page",
      activeName: "contact-page",
      icon: IconAddressBook,
      link: "/super_admin/dashboard/contact-page",
    },
    {
      name: "Lead",
      activeName: "lead",
      icon: IconUser,
      link: "/super_admin/dashboard/lead",
    },
  ];

  useEffect(() => {
    if (!ignore) {
      localStorage.getItem("navLink")
        ? navigate(localStorage.getItem("navLink"))
        : navigate("/super_admin/dashboard");
      localStorage.getItem("selectedTab") === "undefined" ||
      !localStorage.getItem("selectedTab")
        ? setSelectedTab(undefined)
        : setSelectedTab(localStorage.getItem("selectedTab"));
      localStorage.getItem("showDrawer")
        ? setShowDrawer(JSON.parse(localStorage.getItem("showDrawer")))
        : setShowDrawer(false);
      return () => {
        ignore = true;
      };
    }
  }, []);
  return (
    <div className={`md:flex h-screen overflow-y-hidden`}>
      <div className="hidden md:flex flex-col items-center w-64 bg-[#deecff] overflow-y-auto overflow-x-hidden destination-nav shadow-2xl">
        <img alt="logo" src={logo} className="w-20 my-2" />
        <div className="flex flex-col mb-8 w-64 gap-2 h-full justify-start items-center">
          {routes.map((item, ind) => {
            const activeRoute = activeRout.find(
              (route) => route.name === selectedTab
            );
            return (
              <div key={ind}>
                <div
                  onClick={() => {
                    if (!item.sub_menu) {
                      navigate(item.link);
                      localStorage.setItem("navLink", item.link);
                    }
                    setSelectedTab(item.activeName);
                    localStorage.setItem("selectedTab", item.activeName);
                    if (item.activeName === activeRoute.name) {
                      setShowDrawer(!showDrawer);
                      localStorage.setItem("showDrawer", !showDrawer);
                    } else {
                      setShowDrawer(true);
                      localStorage.setItem("showDrawer", true);
                    }
                  }}
                  className={`relative cursor-pointer flex w-56 flex-row items-center h-11 focus:outline-none mx-3 rounded-xl text-gray-600 border-l-4 border-transparent hover:text-white hover:bg-blue-500 hover:shadow-xl ${
                    activeRoute?.links?.includes(item.activeName) &&
                    "bg-blue-500 shadow-xl text-white"
                  }  pr-6`}
                >
                  <div className="flex justify-between items-center w-full  ">
                    <div className="flex items-center select-none">
                      <span className="inline-flex justify-center items-center ml-4">
                        <item.icon className="font-semibold h-5 w-5" />
                      </span>
                      <span className="ml-2 font-semibold text-sm tracking-wide truncate">
                        {item.name}
                      </span>
                    </div>
                    {item.sub_menu &&
                      (selectedTab === item.activeName && showDrawer ? (
                        <ChevronDownIcon className="h-5 w-5" />
                      ) : (
                        <ChevronUpIcon className="h-5 w-5" />
                      ))}
                  </div>
                </div>
                {selectedTab === item.activeName &&
                  item.sub_menu &&
                  showDrawer &&
                  item.sub_menu.map((menu, ind) => (
                    <div
                      onClick={() => {
                        navigate(menu.link);
                        localStorage.setItem("navLink", item.link);
                      }}
                      key={ind}
                      className={`my-3 px-5 relative cursor-pointer flex w-56 flex-row items-center h-10 focus:outline-none mx-3 border-l-4 border-transparent hover:text-blue-500 border-r-2 hover:border-r-blue-500 ${
                        location.pathname.split("/")[3] === menu.activeName
                          ? "border-r-2 border-r-blue-500 text-blue-500"
                          : "text-gray-600"
                      }  pr-6`}
                    >
                      <div className="flex justify-between items-center w-full  ">
                        <div className="flex items-center select-none">
                          <span className="inline-flex justify-center items-center ml-4">
                            <menu.icon className="font-semibold h-5 w-5" />
                          </span>
                          <span className="ml-2 font-semibold text-sm tracking-wide truncate">
                            {menu.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            );
          })}
          <div
            onClick={() => {
              setLogOutModel(true);
            }}
            className={`select-none cursor-pointer flex items-center py-5 h-11 w-56 focus:outline-none mx-3 mb-3 rounded-xl text-gray-600 border-l-4 border-transparent hover:text-white hover:bg-blue-500 hover:shadow-xl  pr-6`}
          >
            <span className="inline-flex justify-center items-center ml-4">
              <ArrowRightStartOnRectangleIcon className="font-semibold h-5 w-5" />
            </span>
            <span className="ml-2 font-semibold text-sm tracking-wide truncate">
              Log Out
            </span>
          </div>
        </div>
      </div>
      {showSideBar && (
        <div className="mx-auto flex justify-between items-center shadow-sm">
          {showSideBar && (
            <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"></div>
          )}
          <div
            className={
              showSideBar
                ? "fixed flex flex-col  top-0 left-0 w-[300px] h-screen bg-[#deecff] z-10 duration-300"
                : "fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300"
            }
          >
            <div className="flex items-center justify-center py-2 border-b">
              <div className="flex justify-center flex-col items-center">
                <img alt="logo" src={logo} className="w-20" />
                <p className="font-bold text-2xl text-blue-500">
                  Moj Ke Saudagar
                </p>
              </div>
            </div>
            <div className="overflow-y-auto overflow-x-hidden flex-grow">
              <div className="cursor-pointer flex flex-col py-4 space-y-1">
                {routes.map((item, ind) => {
                  const activeRoute = activeRout.find(
                    (route) => route.name === selectedTab
                  );
                  return (
                    <div key={ind}>
                      <div
                        onClick={() => {
                          if (!item.sub_menu) {
                            navigate(item.link);
                            localStorage.setItem("navLink", item.link);
                          }
                          setSelectedTab(item.activeName);
                          localStorage.setItem("selectedTab", item.activeName);
                          if (item.activeName === activeRoute.name) {
                            setShowDrawer(!showDrawer);
                            localStorage.setItem("showDrawer", !showDrawer);
                          } else {
                            setShowDrawer(true);
                            localStorage.setItem("showDrawer", true);
                          }
                          if (!item.sub_menu) {
                            setShowSideBar(false);
                            localStorage.setItem("showDrawer", false);
                          }
                        }}
                        className={`relative flex flex-row items-center h-11 focus:outline-none mx-3 rounded-md text-gray-600 border-l-4 border-transparent hover:text-white hover:bg-blue-500 hover:shadow-xl ${
                          activeRoute?.links?.includes(item.activeName) &&
                          "bg-blue-500 shadow-xl text-white"
                        }  pr-6`}
                      >
                        <div className="flex justify-between items-center w-full  ">
                          <div className="flex items-center select-none">
                            <span className="inline-flex justify-center items-center ml-4">
                              <item.icon className="font-semibold h-5 w-5" />
                            </span>
                            <span className="ml-2 font-semibold text-sm tracking-wide truncate">
                              {item.name}
                            </span>
                          </div>
                          {item.sub_menu &&
                            (selectedTab === item.activeName && showDrawer ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            ))}
                        </div>
                      </div>
                      {selectedTab === item.activeName &&
                        item.sub_menu &&
                        showDrawer &&
                        item.sub_menu.map((menu, ind) => (
                          <div
                            onClick={() => {
                              navigate(menu.link);
                              localStorage.setItem("navLink", item.link);
                              setShowSideBar(false);
                            }}
                            key={ind}
                            className={`my-3 px-5 relative cursor-pointer flex w-[17rem] flex-row items-center h-10 focus:outline-none mx-3 border-l-4 border-transparent hover:text-blue-500 border-r-2 hover:border-r-blue-500 ${
                              location.pathname.split("/")[3] ===
                              menu.activeName
                                ? "border-r-2 border-r-blue-500 text-blue-500"
                                : "text-gray-600"
                            }  pr-6`}
                          >
                            <div className="flex justify-between items-center w-full  ">
                              <div className="flex items-center select-none">
                                <span className="inline-flex justify-center items-center ml-4">
                                  <menu.icon className="font-semibold h-5 w-5" />
                                </span>
                                <span className="ml-2 font-semibold text-sm tracking-wide truncate">
                                  {menu.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              onClick={() => {
                setLogOutModel(true);
              }}
              className={`relative cursor-pointer flex flex-row items-center h-11 focus:outline-none mx-3 mb-3 rounded-md text-gray-600 hover:text-white hover:bg-blue-500 hover:shadow-xl border-l-4 border-transparent pr-6`}
            >
              <span className="inline-flex justify-center items-center ml-4">
                <ArrowRightStartOnRectangleIcon className="font-semibold h-5 w-5" />
              </span>
              <span className="ml-2 font-semibold text-sm tracking-wide truncate">
                Log Out
              </span>
            </div>
            <button
              onClick={() => {
                setShowSideBar(false);
              }}
              className="absolute top-0 -right-10"
            >
              <XCircleIcon className="h-10 w-10 text-blue-500" />
            </button>
          </div>
        </div>
      )}
      <LogOutModel
        handleLogOutModel={() => {
          localStorage.removeItem("admin_token");
          window.location.reload();
        }}
        setShowModal={setLogOutModel}
        title={"Log Out"}
        showModal={logOutModel}
      />
      <div className="flex flex-col flex-1 h-full">
        <div className="flex items-center pt-4 pb-3 bg-[#e8f1ff] justify-between px-5 bg-whit w-full">
          <div>
            <div
              onClick={() => {
                setShowSideBar(true);
              }}
              className="flex items-center md:hidden cursor-pointer"
            >
              <Bars3CenterLeftIcon className="h-5 w-5" />
            </div>
            <div className="text-xl font-bold text-[#312e81]">
              Moj Ke Saudagar Admin
            </div>
          </div>
          <div className="flex items-center">
            <Notification />
          </div>
        </div>
        <div className="overflow-y-auto overflow-x-hidden h-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;

const activeRout = [
  {
    name: undefined,
    links: [undefined],
  },
  {
    name: "home-page",
    links: [
      "home-page",
      "destination-option",
      "favourite-destination",
      "top-picks",
      "travel-gallery",
      "testimonial",
    ],
  },
  {
    name: "about-page",
    links: ["about-page"],
  },
  {
    name: "tour-list-page",
    links: ["tour-list-page", "top-destination"],
  },
  {
    name: "travel-itinerary",
    links: [
      "travel-itinerary",
      "travel-itinerary-add",
      "travel-itinerary-edit",
    ],
  },
  {
    name: "blog-page",
    links: ["blog-page", "blog-post", "blog-comment"],
  },
  {
    name: "contact-page",
    links: ["contact-page"],
  },
  {
    name: "lead",
    links: ["lead"],
  },
];
